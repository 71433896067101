jQuery = $ = require('jquery');

// DOMContentLoaded has fired before this async script has loaded
if (window.isReady) {
	jQuery.ready();
}

require('./modules/helpers/scroll.js');
require('./modules/back-to-top.js');
// require('./modules/navigation');
// require('./modules/read-more');
