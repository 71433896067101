// var cssVars = require('./helpers/css-vars');
require('./jquery/passive-scroll');

var changeStart = $('.main-header').outerHeight() + 100;
var $backToTop = $('.back-to-top');

$(window).on('scroll.top', {passive: true}, function() {
	var scrollTop = $(window).scrollTop();
	$backToTop.toggleClass('back-to-top--visible', scrollTop > changeStart);
}).trigger('scroll.top');

