function scrollToElement(fragment, animate) {
	if (!fragment || fragment.substr(0, 3) === 'tab') {
		// ignore empty fragments
		return false;
	}

	var $element = $('#' + fragment);
	if (!$element.length) {
		$element = $('*[name=' + fragment + ']');
	}

	if (!$element.length) {
		// didn't find the destination of the anchor
		return false;
	}

	var top = $element.offset().top;
	var $header = $('.main-header').filter(function(index, element) {
		return $(element).css('position') === 'fixed' || $(element).css('position') === 'sticky';
	});
	if ($header.length) {
		top -= $header.outerHeight();
	}

	if (animate === false) {
		$(window).scrollTop(top);
		return true;
	}

	// jQuery doesn't allow the animation of $(window).
	// Solution: Create a fake element and animate that!
	$('<p>').prop('foo', $(window).scrollTop()).animate({foo: top}, {
		step: function(p) {
			$(window).scrollTop(p);
		}
	});
	return true;
}

$(document).ready(function() {
	$(document).on('click', 'a[href*="#"]', function(event) {
		// check if this link points to an anchor within this document
		if ((this.pathname === location.pathname || '/' + this.pathname === location.pathname) && this.search === location.search) {
			// internal link, check if link can be found
			var fragment = this.hash.substr(1);
			if (scrollToElement(fragment)) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				$(this).blur();
			}
		}
	});
});

if (location.hash) {
	var fragment = location.hash.substr(1);
	scrollToElement(fragment, false);
}

module.exports = scrollToElement;
