// Use Passive event listeners, if available, to improve scroll performance
// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
// Test via a getter in the options object to see if the passive property is accessed
var supportsPassive = false;
try {
	var opts = Object.defineProperty({}, 'passive', {
		get: function() {
			supportsPassive = true;
			return false;
		}
	});
	window.addEventListener('test', null, opts);
} catch (e) {}

jQuery.event.special.scroll = {
	setup: $.noop,

	add: function(handleObj) {
		var elemData = jQuery._data(this);

		var eventHandle = elemData.handle;
		var type = handleObj.type;

		if (this.addEventListener) {
			if (handleObj.data && handleObj.data.passive && supportsPassive) {
				this.addEventListener(type, eventHandle, {passive: true});
			} else {
				this.addEventListener(type, eventHandle, false);
			}
		} else if (this.attachEvent) {
			this.attachEvent('on' + type, eventHandle);
		}
	}
};
